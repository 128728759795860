import * as Yup from "yup"
import remicade_100mg_diagnoses from "./diagnoses/remicade_100mg.json"
import zytiga_250mg_diagnoses from "./diagnoses/zytiga_250mg.json"
import zytiga_500mg_diagnoses from "./diagnoses/zytiga_500mg.json"
import velcade_3_5mg_diagnoses from "./diagnoses/velcade_3.5mg.json"
import imbruvica_140mg_diagnoses from "./diagnoses/imbruvica_140mg.json"
import ribomustin_25mg_diagnoses from "./diagnoses/ribomustin_25mg.json"
import ribomustin_100mg_diagnoses from "./diagnoses/ribomustin_100mg.json"
import stelara_45mg_diagnoses from "./diagnoses/stelara_45mg.json"
import stelara_90mg_diagnoses from "./diagnoses/stelara_90mg.json"
import stelara_130mg_diagnoses from "./diagnoses/stelara_130mg.json"
import simponi_50mg_diagnoses from "./diagnoses/simponi_50mg.json"
import invega_75mg_diagnoses from "./diagnoses/invega_75mg.json"
import invega_100mg_diagnoses from "./diagnoses/invega_100mg.json"
import invega_150mg_diagnoses from "./diagnoses/invega_150mg.json"
import invega_263mg_diagnoses from "./diagnoses/invega_263mg.json"
import invega_350mg_diagnoses from "./diagnoses/invega_350mg.json"
import invega_525mg_diagnoses from "./diagnoses/invega_525mg.json"
import darzalex_100mg_diagnoses from "./diagnoses/darzalex_100mg.json"
import darzalex_400mg_diagnoses from "./diagnoses/darzalex_400mg.json"
import tremfya_100mg_diagnoses from "./diagnoses/tremfya_100mg.json"
import opsumit_10mg_diagnoses from "./diagnoses/opsumit_10mg.json"
import simponiaria_50mg_diagnoses from "./diagnoses/simponiaria_50mg.json"
import ribomustin_25mg_followups from "./diagnoses/followUpQuestions/ribomustin_25mg.json"
import ribomustin_100mg_followups from "./diagnoses/followUpQuestions/ribomustin_100mg.json"
import darzalex_100mg_followups from "./diagnoses/followUpQuestions/darzalex_100mg.json"
import darzalex_400mg_followups from "./diagnoses/followUpQuestions/darzalex_400mg.json"
import velcade_3_5mg_followups from "./diagnoses/followUpQuestions/velcade_3.5mg.json"
import imbruvica_140mg_followups from "./diagnoses/followUpQuestions/imbruvica_140mg.json"
import erleada_60mg_diagnoses from "./diagnoses/erleada_60mg.json"
import uptravi_200mcg_diagnoses from "./diagnoses/uptravi_200mcg.json"
import uptravi_800mcg_diagnoses from "./diagnoses/uptravi_800mcg.json"
import rybrevant_350mg_diagnoses from "./diagnoses/rybrevant_350mg.json"
import darzalex_1800mg_diagnoses from "./diagnoses/darzalex_1800mg.json"

const yearNow = new Date(Date.now()).getFullYear()

export const patientEnrollmentValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  patientAddress: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    barangay: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    addressType: Yup.string().required("This field is required"),
  }),
  birthday: Yup.object({
    month: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number().required("This field is required").nullable(),
    }),
    year: Yup.number()
      .min(1900, "Please input a valid year")
      .max(yearNow, "Please input a valid year")
      .required("This field is required"),
  }),
  secondaryContactFullName: Yup.string().required("This field is required"),
  secondaryContactMobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  hospitalName: Yup.object().shape({
    value: Yup.string().required("This field is required").nullable(),
  }),
  otherHospital: Yup.string().when("hospitalName", {
    is: (hospitalName) => hospitalName?.value === "Other",
    then: Yup.string().required("This field is required"),
  }),
  doctorName: Yup.string().required("This field is required"),
  prescribedMedicine: Yup.object().shape({
    value: Yup.string().required("This field is required").nullable(),
  }),
  paymentMethod: Yup.array().min(1, "This field is required"),
  companyEmployeeBenefits: Yup.string().when("paymentMethod", {
    is: (paymentMethod) => paymentMethod.includes("Company employee benefits"),
    then: Yup.string().required("This field is required"),
  }),
  privateHealthInsurance: Yup.string().when("paymentMethod", {
    is: (paymentMethod) => paymentMethod.includes("Private health insurance"),
    then: Yup.string().required("This field is required"),
  }),
  governmentAssistance: Yup.array().when("paymentMethod", {
    is: (paymentMethod) => paymentMethod.includes("Government assistance"),
    then: Yup.array().min(1, "This field is required"),
  }),
  whereDidYouHearAboutUs: Yup.array().min(1, "This field is required"),
  otherReferral: Yup.string().when("whereDidYouHearAboutUs", {
    is: (referral) => referral.includes("Other"),
    then: Yup.string()
      .required("This field is required")
      .test(
        "no-whitespace",
        "Please enter a valid input.",
        (value) => value && value.trim().length > 0
      ),
  }),
})

export const doctorEnrollmentValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("This field is required"),
  lastName: Yup.string().required("This field is required"),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required("This field is required"),
  emailAddress: Yup.string()
    .email("Please input a valid email")
    .required("This field is required"),
  prcLicenseNumber: Yup.string()
    .min(5, "Please follow this format: 0012345")
    .max(7, "Please follow this format: 0012345")
    .matches(/^[0-9]*$/, "Please follow this format: 0012345")
    .required("This field is required"),
  specialization: Yup.object().shape({
    value: Yup.string().required("This field is required").nullable(),
  }),
  otherSpecialization: Yup.string().required("This field is required"),
  primaryClinic: Yup.object().shape({
    streetAddress: Yup.string().required("This field is required"),
    city: Yup.string().required("This field is required").nullable(),
    province: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    name: Yup.object().shape({
      value: Yup.string().required("This field is required").nullable(),
    }),
    otherHospital: Yup.string().when("name", {
      is: (name) => name?.value === "Other",
      then: Yup.string().required("This field is required"),
    }),
    clinicHours: Yup.string().required("This field is required"),
    clinicContactNumber: Yup.string().required("This field is required"),
  }),
  referral: Yup.object().shape({
    fullName: Yup.string().required("This field is required"),
  }),
})

export const totalHouseholdIncomeChoices = [
  "Less than P200,000",
  "P200,000 - 349,999",
  "P350,000 - 999,999",
  "P1,000,000 - 1,499,999",
  "Equal to or more than P1,500,000",
]

export const paymentMethodChoices = ["Self-pay", "Government assistance"]
export const hasPrivateInsuranceChoices = ["No", "Yes"]

export const prescribedMedicineChoices = [
  {
    label: "Abiraterone acetate 500mg Tablet (Zytiga 60s)",
    value: "Abiraterone acetate 500mg Tablet (Zytiga 60s)",
    sku: "Abiraterone acetate 500mg Tablet (Zytiga 60s)",
    isSolid: true,
    tag: "zytiga_500mg",
    illnesses: zytiga_500mg_diagnoses,
  },
  {
    label: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    value: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    sku:
      "Bortezomib 3.5mg Solution for Injection (Velcade, No Compromise Patient Care Pack of 4s)",
    isSolid: false,
    tag: "velcade_3.5mg",
    illnesses: velcade_3_5mg_diagnoses,
    followUpQuestions: velcade_3_5mg_followups,
  },
  {
    label: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    value: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    sku: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    isSolid: false,
    tag: "velcade_3.5mg",
    illnesses: velcade_3_5mg_diagnoses,
    followUpQuestions: velcade_3_5mg_followups,
  },
  {
    label: "Ibrutinib 140mg Capsule (Imbruvica)",
    value: "Ibrutinib 140mg Capsule (Imbruvica)",
    sku: "Ibrutinib 140mg Capsule (Imbruvica 90s)",
    isSolid: true,
    tag: "imbruvica_140mg",
    illnesses: imbruvica_140mg_diagnoses,
    followUpQuestions: imbruvica_140mg_followups,
  },
  {
    label: "Bendamustine hydrochloride 25mg Vial (Ribomustin)",
    value: "Bendamustine hydrochloride 25mg Vial (Ribomustin)",
    sku: "Bendamustine hydrochloride 25mg Vial (Ribomustin)",
    isSolid: false,
    tag: "ribomustin_25mg",
    illnesses: ribomustin_25mg_diagnoses,
    followUpQuestions: ribomustin_25mg_followups,
  },
  {
    label: "Bendamustine hydrochloride 100mg Vial (Ribomustin)",
    value: "Bendamustine hydrochloride 100mg Vial (Ribomustin)",
    sku: "Bendamustine hydrochloride 100mg Vial (Ribomustin)",
    isSolid: false,
    tag: "ribomustin_100mg",
    illnesses: ribomustin_100mg_diagnoses,
    followUpQuestions: ribomustin_100mg_followups,
  },
  {
    label: "Ustekinumab 45mg/0.5mL Pre-filled syringe (Stelara)",
    value: "Ustekinumab 45mg/0.5mL Pre-filled syringe (Stelara)",
    sku: "Ustekinumab 45mg/0.5mL Pre-filled syringe (Stelara)",
    isSolid: false,
    tag: "stelara_45mg",
    illnesses: stelara_45mg_diagnoses,
  },
  {
    label: "Ustekinumab 90mg/1mL Pre-filled syringe (Stelara)",
    value: "Ustekinumab 90mg/1mL Pre-filled syringe (Stelara)",
    sku: "Ustekinumab 90mg/1mL Pre-filled syringe (Stelara)",
    isSolid: false,
    tag: "stelara_90mg",
    illnesses: stelara_90mg_diagnoses,
  },
  {
    label: "Ustekinumab 130mg 5mg/mL Vial (Stelara)",
    value: "Ustekinumab 130mg 5mg/mL Vial (Stelara)",
    sku: "Ustekinumab 130mg 5mg/mL Vial (Stelara)",
    isSolid: false,
    tag: "stelara_130mg",
    illnesses: stelara_130mg_diagnoses,
  },
  {
    label: "Golimumab 50mg/0.5mL Auto-injection (Simponi)",
    value: "Golimumab 50mg/0.5mL Auto-injection (Simponi)",
    sku: "Golimumab 50mg/0.5mL Auto-injection (Simponi)",
    isSolid: false,
    tag: "simponi_50mg",
    illnesses: simponi_50mg_diagnoses,
  },
  {
    label: "Guselkumab 100mg Pre-filled pen (Tremfya)",
    value: "Guselkumab 100mg Pre-filled pen (Tremfya)",
    sku: "Guselkumab 100mg Pre-filled pen (Tremfya)",
    isSolid: false,
    tag: "tremfya_100mg",
    illnesses: tremfya_100mg_diagnoses,
  },
  {
    label: "Paliperidone palmitate 75mg Injection (Invega Sustenna)",
    value: "Paliperidone palmitate 75mg Injection (Invega Sustenna)",
    sku: "Paliperidone palmitate 75mg Injection (Invega Sustenna)",
    isSolid: false,
    tag: "invega_75mg",
    illnesses: invega_75mg_diagnoses,
  },
  {
    label: "Paliperidone palmitate 100mg Injection (Invega Sustenna)",
    value: "Paliperidone palmitate 100mg Injection (Invega Sustenna)",
    sku: "Paliperidone palmitate 100mg Injection (Invega Sustenna)",
    isSolid: false,
    tag: "invega_100mg",
    illnesses: invega_100mg_diagnoses,
  },
  {
    label: "Paliperidone palmitate 150mg Injection (Invega Sustenna)",
    value: "Paliperidone palmitate 150mg Injection (Invega Sustenna)",
    sku: "Paliperidone palmitate 150mg Injection (Invega Sustenna)",
    isSolid: false,
    tag: "invega_150mg",
    illnesses: invega_150mg_diagnoses,
  },
  {
    label: "Paliperidone palmitate 263mg Injection (Invega Trinza)",
    value: "Paliperidone palmitate 263mg Injection (Invega Trinza)",
    sku: "Paliperidone palmitate 263mg Injection (Invega Trinza)",
    isSolid: false,
    tag: "invega_263mg",
    illnesses: invega_263mg_diagnoses,
  },
  {
    label: "Paliperidone palmitate 350mg Injection (Invega Trinza)",
    value: "Paliperidone palmitate 350mg Injection (Invega Trinza)",
    sku: "Paliperidone palmitate 350mg Injection (Invega Trinza)",
    isSolid: false,
    tag: "invega_350mg",
    illnesses: invega_350mg_diagnoses,
  },
  {
    label: "Paliperidone palmitate 525mg Injection (Invega Trinza)",
    value: "Paliperidone palmitate 525mg Injection (Invega Trinza)",
    sku: "Paliperidone palmitate 525mg Injection (Invega Trinza)",
    isSolid: false,
    tag: "invega_525mg",
    illnesses: invega_525mg_diagnoses,
  },
  {
    label: "Infliximab 100mg Vial (Remicade)",
    value: "Infliximab 100mg Vial (Remicade)",
    sku: "Infliximab 100mg Vial (Remicade)",
    isSolid: false,
    tag: "remicade_100mg",
    illnesses: remicade_100mg_diagnoses,
  },
  {
    label: "Daratumumab 100mg Vial (Darzalex)",
    value: "Daratumumab 100mg Vial (Darzalex)",
    sku: "Daratumumab 100mg/5mL Vial (Darzalex)",
    isSolid: false,
    tag: "darzalex_100mg",
    illnesses: darzalex_100mg_diagnoses,
    followUpQuestions: darzalex_100mg_followups,
  },
  {
    label: "Daratumumab 400mg Vial (Darzalex)",
    value: "Daratumumab 400mg Vial (Darzalex)",
    sku: "Daratumumab 400mg/20mL Vial (Darzalex)",
    isSolid: false,
    tag: "darzalex_400mg",
    illnesses: darzalex_400mg_diagnoses,
    followUpQuestions: darzalex_400mg_followups,
  },
  {
    label: "Macitentan 10mg Tablet (Opsumit)",
    value: "Macitentan 10mg Tablet (Opsumit)",
    sku: "Macitentan 10mg Tablet (Opsumit 30s)",
    isSolid: true,
    tag: "opsumit_10mg",
    illnesses: opsumit_10mg_diagnoses,
  },
  {
    label: "Golimumab 50mg/4mL Vial (Simponi Aria)",
    value: "Golimumab 50mg/4mL Vial (Simponi Aria)",
    sku: "Golimumab 50mg/4mL Vial (Simponi Aria)",
    isSolid: false,
    tag: "simponiaria_50mg",
    illnesses: simponiaria_50mg_diagnoses,
  },
  {
    label: "Apalutamide 60mg Tablet (Erleada)",
    value: "Apalutamide 60mg Tablet (Erleada)",
    sku: "Apalutamide 60mg Tablet (Erleada 120s)",
    isSolid: true,
    tag: "erleada_60mg",
    illnesses: erleada_60mg_diagnoses,
  },
  {
    label: "Selexipag 200mcg Film-Coated Tablet (Uptravi)",
    value: "Selexipag 200mcg Film-Coated Tablet (Uptravi)",
    sku: "Selexipag 200mcg Film-Coated Tablet (Uptravi 140s)",
    isSolid: true,
    tag: "uptravi_200mcg",
    illnesses: uptravi_200mcg_diagnoses,
  },
  {
    label: "Selexipag 800mcg Film-Coated Tablet (Uptravi)",
    value: "Selexipag 800mcg Film-Coated Tablet (Uptravi)",
    sku: "Selexipag 800mcg Film-Coated Tablet (Uptravi 60s)",
    isSolid: true,
    tag: "uptravi_800mcg",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    label: "Selexipag 400mcg Film-Coated Tablet (Uptravi)",
    value: "Selexipag 400mcg Film-Coated Tablet (Uptravi)",
    sku: "Selexipag 400mcg Film-Coated Tablet (Uptravi 60s)",
    isSolid: true,
    tag: "uptravi_400mcg",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    label: "Selexipag 1600mcg Film-Coated Tablet (Uptravi)",
    value: "Selexipag 1600mcg Film-Coated Tablet (Uptravi)",
    sku: "Selexipag 1600mcg Film-Coated Tablet (Uptravi 60s)",
    isSolid: true,
    tag: "uptravi_1600mcg",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    label: "Amivantamab 350mg Vial (Rybrevant)",
    value: "Amivantamab 350mg Vial (Rybrevant)",
    sku: "Amivantamab 350mg Vial (Rybrevant)",
    isSolid: true,
    tag: "rybrevant_350mg",
    illnesses: rybrevant_350mg_diagnoses,
  },
  {
    label: "Daratumumab 1800mg Vial (Darzalex)",
    value: "Daratumumab 1800mg Vial (Darzalex)",
    sku: "Daratumumab 1800mg Vial (Darzalex)",
    isSolid: true,
    tag: "darzalex_1800mg",
    illnesses: darzalex_1800mg_diagnoses,
  },
]

export const specializationChoices = [
  {
    label: "Dermatology",
    value: "Dermatology",
  },
  {
    label: "Gastroenterology",
    value: "Gastroenterology",
  },
  {
    label: "Hematology",
    value: "Hematology",
  },
  {
    label: "Immunology",
    value: "Immunology",
  },
  {
    label: "Internal Medicine",
    value: "Internal Medicine",
  },
  {
    label: "Medical Oncology",
    value: "Medical Oncology",
  },
  {
    label: "Psychiatry",
    value: "Psychiatry",
  },
  {
    label: "Rheumatology",
    value: "Rheumatology",
  },
  {
    label: "Urology",
    value: "Urology",
  },
  {
    label: "Other (please specify)",
    value: "Other",
  },
]

export const specialOrderPrescribedMedicineChoices = [
  {
    sku: "Abiraterone acetate 250mg Tablet (Zytiga 120s)",
    illnesses: zytiga_250mg_diagnoses,
  },
  {
    sku: "Abiraterone acetate 500mg Tablet (Zytiga 60s)",
    illnesses: zytiga_500mg_diagnoses,
  },
  {
    sku:
      "Bortezomib 3.5mg Solution for Injection (Velcade, No Compromise Patient Care Pack of 4s)",
    illnesses: velcade_3_5mg_diagnoses,
    followUpQuestions: velcade_3_5mg_followups,
  },
  {
    sku: "Bortezomib 3.5mg Solution for Injection (Velcade)",
    illnesses: velcade_3_5mg_diagnoses,
    followUpQuestions: velcade_3_5mg_followups,
  },
  {
    sku: "Ibrutinib 140mg Capsule (Imbruvica 90s)",
    illnesses: imbruvica_140mg_diagnoses,
    followUpQuestions: imbruvica_140mg_followups,
  },
  {
    sku: "Bendamustine hydrochloride 25mg Vial (Ribomustin)",
    illnesses: ribomustin_25mg_diagnoses,
    followUpQuestions: ribomustin_25mg_followups,
  },
  {
    sku: "Bendamustine hydrochloride 100mg Vial (Ribomustin)",
    illnesses: ribomustin_100mg_diagnoses,
    followUpQuestions: ribomustin_100mg_followups,
  },
  {
    sku: "Ustekinumab 45mg/0.5mL Pre-filled syringe (Stelara)",
    illnesses: stelara_45mg_diagnoses,
  },
  {
    sku: "Ustekinumab 90mg/1mL Pre-filled syringe (Stelara)",
    illnesses: stelara_90mg_diagnoses,
  },
  {
    sku: "Ustekinumab 130mg 5mg/mL Vial (Stelara)",
    illnesses: stelara_130mg_diagnoses,
  },
  {
    sku: "Golimumab 50mg/0.5mL Auto-injection (Simponi)",
    illnesses: simponi_50mg_diagnoses,
  },
  {
    sku: "Golimumab 50mg/4mL Vial (Simponi Aria)",
    illnesses: simponiaria_50mg_diagnoses,
  },
  {
    sku: "Guselkumab 100mg Pre-filled pen (Tremfya)",
    illnesses: tremfya_100mg_diagnoses,
  },
  {
    sku: "Paliperidone palmitate 75mg Injection (Invega Sustenna)",
    illnesses: invega_75mg_diagnoses,
  },
  {
    sku: "Paliperidone palmitate 100mg Injection (Invega Sustenna)",
    illnesses: invega_100mg_diagnoses,
  },
  {
    sku: "Paliperidone palmitate 150mg Injection (Invega Sustenna)",
    illnesses: invega_150mg_diagnoses,
  },
  {
    sku: "Paliperidone palmitate 263mg Injection (Invega Trinza)",
    illnesses: invega_263mg_diagnoses,
  },
  {
    sku: "Paliperidone palmitate 350mg Injection (Invega Trinza)",
    illnesses: invega_350mg_diagnoses,
  },
  {
    sku: "Paliperidone palmitate 525mg Injection (Invega Trinza)",
    illnesses: invega_525mg_diagnoses,
  },
  {
    sku: "Infliximab 100mg Vial (Remicade)",
    illnesses: remicade_100mg_diagnoses,
  },
  {
    sku: "Daratumumab 100mg/5mL Vial (Darzalex)",
    illnesses: darzalex_100mg_diagnoses,
    followUpQuestions: darzalex_100mg_followups,
  },
  {
    sku: "Daratumumab 400mg/20mL Vial (Darzalex)",
    illnesses: darzalex_400mg_diagnoses,
    followUpQuestions: darzalex_400mg_followups,
  },
  {
    sku: "Macitentan 10mg Tablet (Opsumit 30s)",
    illnesses: opsumit_10mg_diagnoses,
  },
  {
    sku: "Apalutamide 60mg Tablet (Erleada 120s)",
    illnesses: erleada_60mg_diagnoses,
  },
  {
    sku: "Selexipag 200mcg Film-Coated Tablet (Uptravi 140s)",
    illnesses: uptravi_200mcg_diagnoses,
  },
  {
    sku: "Selexipag 800mcg Film-Coated Tablet (Uptravi 60s)",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    sku: "Selexipag 400mcg Film-Coated Tablet (Uptravi 60s)",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    sku:
      "Selexipag 200mcg Film-Coated Tablet (Uptravi 140s) + Macitentan 10mg Tablet (Opsumit 30s)",
    illnesses: uptravi_200mcg_diagnoses,
  },
  {
    sku:
      "Selexipag 800mcg Film-Coated Tablet (Uptravi 60s) + Macitentan 10mg Tablet (Opsumit 30s)",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    sku:
      "Selexipag 400mcg Film-Coated Tablet (Uptravi 60s) + Macitentan 10mg Tablet (Opsumit 30s)",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    sku: "Selexipag 1600mcg Film-Coated Tablet (Uptravi 60s)",
    illnesses: uptravi_800mcg_diagnoses,
  },
  {
    sku:
      "Selexipag 1600mcg Film-Coated Tablet (Uptravi 60s) + Macitentan 10mg Tablet (Opsumit 30s)",
    illnesses: uptravi_800mcg_diagnoses,
  },
]

export const patientAdherenceInformationChoices = [
  "My patients' adherence information",
  "Patient educational materials",
]

export const contactThroughChoices = ["Email", "SMS", "Call"]

export const callFrequencyChoices = [
  "Once every one (1) month",
  "Once every three (3) months",
  "Once every six (6) months",
  "Once a year",
]

export const bestTimeToCallOptions = ["9AM-12PM", "12PM-3PM", "3PM-5PM"]

export const FOLLOWUP_QUESTION_NAMES = [
  "diseaseType",
  "lineOfTreatment",
  "therapyType",
  "combinationTherapyMedicines",
]
